import React from 'react';
import type { ReactNode } from 'react';

import toast from 'react-hot-toast';

import { FileRejection, useDropzone } from 'react-dropzone';

import { FlexBox } from '@eltoro-ui/components';

import warning_icon from 'assets/Images/warning_icon.svg';

import './CreativesGeneratorUploader.scss';
import classNames from 'classnames';
import CustomIcons from '../../../../../../../../../../../../assets/icons';

interface CreativesGeneratorUploaderProps {
  title: string;
  file: File | null;
  onUpload: (file: File) => void;
  children: ReactNode;
}

export default function CreativesGeneratorUploader({
  children,
  title,
  file,
  onUpload,
}: CreativesGeneratorUploaderProps) {
  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    fileRejections.forEach(file => {
      const TypeError = file.errors.filter(eType => eType.code === 'file-invalid-type');
      if (TypeError?.[0].code === 'file-invalid-type') {
        toast.error(`File format is not supported. (${file.file.name})`, {
          icon: <img src={warning_icon} alt="warn" />,
        });
      }
    });

    if (fileRejections.length) return;
    onUpload(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.gif', '.png', '.jpg'],
    },
    multiple: false,
  });

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      gap="1.25rem"
      UNSAFE_className="creative-uploader"
    >
      <div className={classNames('upload-photo', { uploaded: !!file })} {...getRootProps()}>
        <div className="uploader-mask">
          <CustomIcons name={file ? 'replace' : 'upload'} color="#FEFEFE" fontSize={24} />
        </div>
        <input {...getInputProps()} />
        {file ? (
          <img src={URL.createObjectURL(file)} alt="uploaded-photo" className="uploaded-file" />
        ) : (
          children
        )}
      </div>
      <span className="creative-uploader-title">{title}</span>
    </FlexBox>
  );
}

import React, { useState } from 'react';

import { Galleria, GalleriaResponsiveOptions } from 'primereact/galleria';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { FlexBox } from '@eltoro-ui/components';

import { useMagicCreativeContext } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/context/MagicCreativeContext';

import type { TGeneratedBanner } from 'types';

export default function CreativesGalleria() {
  const {
    generatedBanners: [banners],
  } = useMagicCreativeContext();
  const [activeIndex, setActiveIndex] = useState(0);

  const responsiveOptions: GalleriaResponsiveOptions[] = [
    {
      breakpoint: '991px',
      numVisible: 4,
    },
    {
      breakpoint: '767px',
      numVisible: 3,
    },
    {
      breakpoint: '575px',
      numVisible: 1,
    },
  ];

  const next = () => {
    setActiveIndex(prevState => (prevState === banners.length - 1 ? 0 : prevState + 1));
  };

  const prev = () => {
    setActiveIndex(prevState => (prevState === 0 ? 0 : prevState - 1));
  };

  const itemTemplate = (item: TGeneratedBanner) => {
    return (
      <div className="image-preview-container">
        <img src={item.url} alt={item.banner_uuid} className="image-preview" />
      </div>
    );
  };

  const thumbnailTemplate = (item: TGeneratedBanner) => {
    return (
      <div className="gallery-image-preview-container">
        <img src={item.url} alt={item.banner_uuid} className="gallery-preview-image" />
      </div>
    );
  };

  return (
    <FlexBox flexDirection="column" UNSAFE_className="magic-banners-usage" gap="0.5rem">
      <FlexBox alignItems="center" justifyContent="space-between">
        <span className="images-info">Ad Preview</span>
        <FlexBox alignItems="center" gap="0.5rem">
          <button
            aria-label="prev"
            className="galleria-action-btn"
            disabled={activeIndex === 0}
            onClick={prev}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ color: activeIndex === 0 ? '#D1D1D1' : '#757575', fontSize: 18 }}
            />
          </button>
          <span className="images-count">
            {activeIndex + 1}/{banners.length}
          </span>
          <button
            aria-label="next"
            className="galleria-action-btn"
            disabled={activeIndex === banners.length - 1}
            onClick={next}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{
                color: activeIndex === banners.length - 1 ? '#D1D1D1' : '#757575',
                fontSize: 18,
              }}
            />
          </button>
        </FlexBox>
      </FlexBox>
      <Galleria
        showThumbnailNavigators={false}
        value={banners}
        activeIndex={activeIndex}
        onItemChange={e => setActiveIndex(e.index)}
        responsiveOptions={responsiveOptions}
        numVisible={6}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
        // style={{ maxWidth: '640px' }}
      />
    </FlexBox>
  );
}

import React from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import MagicCreativeContextProvider from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/context/MagicCreativeContext';

import { CampaignSetup, CampaignCheckout, CampaignCreatives } from './components';

import './CampaignCreation.scss';

export const CampaignCreation = () => {
  const { url } = useRouteMatch();

  return (
    <div className="CampaignCreation">
      <MagicCreativeContextProvider>
        <Switch>
          <Route path={`${url}/:campaignId/setup`} component={CampaignSetup} />
          <Route path={`${url}/:campaignId/creatives`} component={CampaignCreatives} />
          <Route path={`${url}/:campaignId/calculate`} component={CampaignCheckout} />
        </Switch>
      </MagicCreativeContextProvider>
    </div>
  );
};

import React from 'react';

import { useMagicCreativeContext } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/context/MagicCreativeContext';

import MagicGeneratorState from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/MagicBannersUsage/components/MagicGeneratorState';
import CreativesGalleria from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/MagicBannersUsage/components/CreativesGalleria';

import './MagicBannersUsage.scss';

export default function MagicBannersUsage() {
  const {
    generateHasError: [generateHasError],
    generateLoading: [generateLoading],
  } = useMagicCreativeContext();

  if (generateLoading) return <MagicGeneratorState state="loading" />;

  if (!generateLoading && generateHasError) return <MagicGeneratorState state="error" />;

  return <CreativesGalleria />;
}

import React from 'react';
import { Button, Modal } from '@eltoro-ui/components';
import classNames from 'classnames';
import { CreativeTypeEnum } from 'enums';
import type { CreativeActionUrls } from 'types';
import AttachCreativeByType from '../../AttachCreativeByType';
import './CreativeTypeBadge.scss';

type CreativeTypeBadgeProps = {
  onClick: () => void;
  onClickGenerateCreative?: () => void;
  onClickChooseFromCreativeLibrary?: () => void;
  onClickGenerateWithAI?: VoidFunction;
  handleCloseCreativeActionsModal: (creativeType: CreativeTypeEnum, isOpen: boolean) => void;
  uploadCreativeActionsOpen: CreativeActionUrls;
  text: string;
  bannerIcon?: string;
  typeBadge: CreativeTypeEnum;
  disabledDirectMail?: boolean;
  UNSAFE_className?: string;
};

export const CreativeTypeBadge = ({
  onClick,
  onClickGenerateCreative,
  onClickChooseFromCreativeLibrary,
  onClickGenerateWithAI,
  handleCloseCreativeActionsModal,
  uploadCreativeActionsOpen,
  text,
  bannerIcon,
  typeBadge,
  disabledDirectMail,
  UNSAFE_className = '',
}: CreativeTypeBadgeProps) => {
  return (
    <div className={classNames('CampaignSetup__box', UNSAFE_className)}>
      <div style={{ marginBlock: '1rem' }}>
        <img
          className="CampaignSetup__box__icon"
          src={bannerIcon}
          alt="creative-type"
          style={{ marginBottom: '8px', marginTop: '12px' }}
        />
      </div>
      <div className="creative-action-popup-container">
        <Button
          type="button"
          kind="primary"
          size="l"
          weight="bold"
          onClick={() => handleCloseCreativeActionsModal(typeBadge, true)}
          aria-controls="popup_menu_left"
          aria-haspopup
          UNSAFE_className="CreativeTypeBadge__btn"
          disabled={disabledDirectMail}
        >
          {text}
        </Button>
        {typeBadge && uploadCreativeActionsOpen[typeBadge] && (
          <Modal offClick={() => handleCloseCreativeActionsModal(typeBadge, false)}>
            <AttachCreativeByType
              typeBadge={typeBadge}
              closeModal={() => handleCloseCreativeActionsModal(typeBadge, false)}
              onUploadFromPC={onClick}
              onClickGenerateWithAI={() => {
                onClickGenerateWithAI?.();
              }}
              onClickChooseFromCreativeLibrary={() => {
                if (onClickChooseFromCreativeLibrary) onClickChooseFromCreativeLibrary();
              }}
              onClickGenerateCreative={() => {
                if (onClickGenerateCreative) onClickGenerateCreative();
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

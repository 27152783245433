import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import SupportErrorPages from 'Pages/SupportErrorPages';

import routes from 'routes';

const nestedRoutesParents = [
  '/create-campaign',
  '/profile',
  '/my-sphere',
  '/prospects',
  '/campaigns',
  '/orderlines',
  '/activity-alerts',
];

export const PageNotFound = (props: RouteComponentProps) => {
  const checkRoute = useMemo(
    () =>
      routes.some(route => props.match.url === route.path) ||
      nestedRoutesParents.some(parentRoute => props.match.url.includes(parentRoute)),
    [props.match.url]
  );

  if (checkRoute) return null;
  if (!checkRoute && !localStorage.getItem('beewo_token')) return <Redirect to="/login" />;

  return <SupportErrorPages type="not-found" />;
};

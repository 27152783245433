import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faExclamationTriangle,
  faHeadset,
  faInfoCircle,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import type { Column } from 'react-table';
import type { MenuItem } from 'primereact/menuitem';
import moment from 'moment';
import OrderlineStatusAccessor from 'Pages/CampaignView/components/OrderlinesTable/components/OrderlineStatusAccessor';
import { CampaignStatusEnum, CampaignStep, OrderlineStatusEnum } from 'enums';
import type { TCampaign, TOrderline } from 'types';
import CustomIcons from 'assets/icons';
import { FlexBox } from '@eltoro-ui/components';
import ToggleMenu from './ToggleMenu';

export const getInfoIconAndTooltip = (campaign: TCampaign) => {
  if (
    campaign.status === CampaignStatusEnum.ERROR ||
    campaign.status === CampaignStatusEnum.PAYMENT_FAILED
  ) {
    return (
      <Tooltip
        placement="topRight"
        trigger="hover"
        overlayClassName={classNames('audience-tooltip', {
          'campaign-status-error': campaign.step !== CampaignStep.DONE,
        })}
        showArrow
        align={{
          offset: [5, -3],
          targetOffset: [-6, 0],
        }}
        overlay={
          campaign.step === CampaignStep.DONE
            ? 'Campaign creation has failed.'
            : 'There are errors in your campaign. Please review them before publishing.'
        }
        getTooltipContainer={() => document.body}
      >
        <span style={{ cursor: 'pointer', display: 'flex' }}>
          <FontAwesomeIcon icon={faExclamationCircle} color="#FF0000" style={{ fontSize: 20 }} />
        </span>
      </Tooltip>
    );
  }
  return null;
};

export const getOderlineWarningIcon = (orderline: TOrderline) => {
  const showTooltipIfPaused =
    orderline.status === OrderlineStatusEnum.PAUSED &&
    moment(orderline.end_date).isBefore(moment());
  if (orderline.status === OrderlineStatusEnum.REJECTED || showTooltipIfPaused) {
    return (
      <Tooltip
        placement="topRight"
        trigger="hover"
        overlayClassName="audience-tooltip"
        showArrow
        align={{
          offset: [5, -3],
          targetOffset: [-6, 0],
        }}
        overlay={
          orderline.status === OrderlineStatusEnum.REJECTED ? (
            <p style={{ width: '285px' }}>
              Your ad was rejected. Check the rejection note for details and make the needed
              updates.
              <br />
              Rejection Note: “Your banners are missing essential elements like a logo, image, or
              call-to-action.”
            </p>
          ) : (
            <p style={{ width: '200px' }}>
              You orderline end date is passed,
              <br /> Please contact support at help@beewo.com for assistance.
            </p>
          )
        }
        getTooltipContainer={() => document.body}
      >
        <span style={{ cursor: 'pointer', display: 'flex' }}>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ fontSize: 18, color: '#FFAB03' }}
          />
        </span>
      </Tooltip>
    );
  }
  return null;
};
export const redirect = (campaign: TCampaign) => {
  if (campaign.step === CampaignStep.AUDIENCE) {
    return `/create-campaign/${campaign?.id}/setup`;
  }
  if (campaign.step === CampaignStep.CREATIVE) {
    return `/create-campaign/${campaign?.id}/creatives`;
  }
  if (campaign.step === CampaignStep.CHECKOUT) {
    return `/create-campaign/${campaign?.id}/calculate`;
  }

  return `/campaigns/${campaign.id}`;
};

export const campaignActionItems = (
  campaign: TCampaign,
  userIsActive: boolean,
  onDelete: (campaign: TCampaign) => void
): MenuItem[] => {
  const edit = {
    label: 'Continue editing',
    icon: <CustomIcons name="edit" color="#222124" fontSize={12} />,
    command: () => (userIsActive ? window.location.replace(redirect(campaign)) : {}),
  };
  return [
    ...(campaign.status === CampaignStatusEnum.CREATING && campaign.step !== CampaignStep.DONE
      ? [edit]
      : []),
    {
      label: 'Delete',
      className: 'delete',
      icon: <FontAwesomeIcon icon={faTrash} color="#ED6762" style={{ fontSize: 12 }} />,
      command: () => onDelete(campaign),
    },
  ];
};

export const orderlineActionItems = (
  orderline: TOrderline,
  handleOpenRenameModal: (open: boolean, orderline: TOrderline) => void
): MenuItem[] => {
  const rename = {
    label: 'Rename',
    icon: <CustomIcons name="edit" color="#222124" fontSize={12} />,
    command: () => handleOpenRenameModal(true, orderline),
  };
  const viewDetails = {
    label: 'View details',
    url: `/orderlines/${orderline.orderline_uuid}`,
    icon: <FontAwesomeIcon icon={faInfoCircle} name="details" color="#222124" fontSize={12} />,
  };
  const contactSupport = {
    label: 'Contact Support',
    url: '/profile/notifications',
    target: '_blank',
    icon: <FontAwesomeIcon icon={faHeadset} color="#222124" fontSize={12} name="support" />,
  };

  if (
    orderline.status === OrderlineStatusEnum.PAUSED &&
    moment(orderline.end_date).isBefore(moment())
  ) {
    return [rename, contactSupport];
  }
  if (orderline.status === OrderlineStatusEnum.COMPLETED) {
    return [viewDetails];
  }
  return [rename, viewDetails];
};

export const getCampaignActions = (
  campaign: TCampaign,
  userIsActive: boolean,
  onDelete: (campaign: TCampaign) => void
) => {
  if (campaign.status === CampaignStatusEnum.CREATING) {
    if (campaign.step !== CampaignStep.DONE)
      return (
        <ToggleMenu
          menuItems={campaignActionItems(campaign, userIsActive, onDelete)}
          isButtonDisable={false}
        />
      );

    return (
      <FontAwesomeIcon
        icon={faSpinnerThird}
        color="#FFAB03"
        style={{ fontSize: 20, animation: 'spinner-border 0.75s linear infinite' }}
      />
    );
  }
  if (campaign.status === CampaignStatusEnum.ERROR && campaign.step === CampaignStep.DONE) {
    return (
      <ToggleMenu
        menuItems={campaignActionItems(campaign, userIsActive, onDelete)}
        isButtonDisable={false}
      />
    );
  }
  return (
    <ToggleMenu menuItems={campaignActionItems(campaign, userIsActive, onDelete)} isButtonDisable />
  );
};

export const getOrderlineActions = (
  orderline: TOrderline,
  userIsActive: boolean,
  handleOpenRenameModal: (open: boolean, orderline: TOrderline) => void
) => {
  return (
    <ToggleMenu
      menuItems={orderlineActionItems(orderline, handleOpenRenameModal)}
      isButtonDisable={!userIsActive}
    />
  );
};

export const onViewDetailCampaign = (
  campaign: TCampaign,
  userIsActive: boolean,
  onDelete: (campaign: TCampaign) => void
) => {
  return (
    <FlexBox
      alignItems="center"
      justifyContent="flex-end"
      gap="12px"
      UNSAFE_style={{ marginInlineEnd: '8px' }}
    >
      {getInfoIconAndTooltip(campaign)}
      {getCampaignActions(campaign, userIsActive, onDelete)}
    </FlexBox>
  );
};

export const orderlineAction = (
  orderline: TOrderline,
  userIsActive: boolean,
  handleOpenRenameModal: (open: boolean, orderline: TOrderline) => void
) => {
  return (
    <FlexBox
      alignItems="center"
      justifyContent="flex-end"
      gap="12px"
      UNSAFE_style={{ marginInlineEnd: '8px' }}
    >
      {getOderlineWarningIcon(orderline)}
      {getOrderlineActions(orderline, userIsActive, handleOpenRenameModal)}
    </FlexBox>
  );
};

export const getCampaignColumns = (
  userIsActive: boolean,
  onDelete: (campaign: TCampaign) => void
): Column<TCampaign>[] => [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Campaign Name',
        accessor: 'name',
        Cell: ({ row: { original: campaign } }) => (
          <FlexBox
            flexDirection="column"
            gap={
              campaign.status === CampaignStatusEnum.CREATING && campaign.step !== CampaignStep.DONE
                ? '2px'
                : '0'
            }
          >
            <span className="campaign-name">{campaign.name}</span>
            {campaign.status === CampaignStatusEnum.CREATING &&
              campaign.step !== CampaignStep.DONE && <span className="campaign-status">Draft</span>}
          </FlexBox>
        ),
      },
      {
        Header: 'Orderlines',
        accessor: campaign => campaign.orderlineCount || '-',
      },
      {
        Header: 'Campaign Goal',
        accessor: campaign => campaign.goal || '-',
      },
      {
        Header: '   ',
        id: 'actions',
        accessor: campaign => onViewDetailCampaign(campaign, userIsActive, onDelete),
      },
    ],
  },
];

export const getOrderlineColumns = (
  userIsActive: boolean,
  handleOpenRenameModal: (open: boolean, orderline: TOrderline) => void
): Column<TOrderline>[] => [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Ad Name',
        accessor: 'name',
        Cell: ({ row: { original: orderline } }) => (
          <FlexBox flexDirection="column">
            <span className="campaign-name">{orderline.name}</span>
          </FlexBox>
        ),
      },
      {
        Header: 'Campaign',
        accessor: orderline => orderline.campaign || '-',
      },
      {
        Header: 'Status',
        accessor: orderline => <OrderlineStatusAccessor status={orderline.status} /> || '-',
      },
      {
        Header: 'Type',
        accessor: orderline => orderline.type || '-',
      },
      {
        Header: 'Start Date',
        accessor: orderline => moment(orderline.start_date).format('MM/DD/YY') || '-',
      },
      {
        Header: 'End Date',
        accessor: orderline => moment(orderline.end_date).format('MM/DD/YY') || '-',
      },
      {
        Header: '   ',
        id: 'actions',
        accessor: orderline => orderlineAction(orderline, userIsActive, handleOpenRenameModal),
      },
    ],
  },
];

import React, { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode, Dispatch, SetStateAction } from 'react';

import { colors } from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/components/MagicCreativeContent/components/FillDetails/components/BannerBackgroundColorPicker/constants';

import { CampaignGoals } from 'Utils/constants';
import type { CampaignGoalType } from 'Utils/constants';

import { GoalSectionTitles } from 'enums';
import type { TGeneratedBanner } from 'types';
import {
  steps,
  StepType,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/MagicCreativeGenerator/constants';

interface ICreativeFormStateType {
  agentPhoto: File | null;
  listingPhoto: File | null;
  agencyLogo: File | null;
  agent_name: string;
  agent_phone: string;
  // color: string;
}

interface IListingGaol extends ICreativeFormStateType {
  type: 'listing';
  listing_address: string;
  real_estate_agency: string;
  bed: string;
  bath: string;
  sqft: string;
  call_to_action?: string;
}
interface IAgentGaol extends ICreativeFormStateType {
  type: 'agent';
  agent_word: string;
  team_slogan: string;
}

type MagicCreativeContextType = {
  formState: [
    state: IListingGaol | IAgentGaol,
    setter: Dispatch<SetStateAction<IListingGaol | IAgentGaol>>
  ];
  isValidForm: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  generateHasError: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  useGeneratedBannersLoading: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  generateLoading: [state: boolean, setter: Dispatch<SetStateAction<boolean>>];
  generatedBanners: [
    state: TGeneratedBanner[],
    setter: Dispatch<SetStateAction<TGeneratedBanner[]>>
  ];
  selectedGoal: CampaignGoalType;
  activeStep: StepType;
  onStepChange: (step: StepType) => void;
  onSelectGoal: (goal: CampaignGoalType) => void;
  clearAllStates: VoidFunction;
};

const defaultAgentGoalValue: IAgentGaol = {
  type: 'agent',
  agent_word: '',
  team_slogan: '',
  agentPhoto: null,
  listingPhoto: null,
  agencyLogo: null,
  agent_name: '',
  agent_phone: '',
  // color: colors[0],
};

const defaultListingGoalValue: IListingGaol = {
  type: 'listing',
  listing_address: '',
  real_estate_agency: '',
  bath: '',
  bed: '',
  sqft: '',
  agentPhoto: null,
  listingPhoto: null,
  agencyLogo: null,
  agent_name: '',
  agent_phone: '',
  // color: colors[0],
};

const defaultValue: MagicCreativeContextType = {
  formState: [defaultAgentGoalValue, () => undefined],
  isValidForm: [false, () => false],
  generateHasError: [false, () => false],
  useGeneratedBannersLoading: [false, () => false],
  generateLoading: [false, () => false],
  generatedBanners: [[], () => []],
  selectedGoal: CampaignGoals[0],
  activeStep: steps[0],
  onStepChange: () => undefined,
  onSelectGoal: () => undefined,
  clearAllStates: () => undefined,
};

const MagicCreativeContext = createContext<MagicCreativeContextType>(defaultValue);

export const useMagicCreativeContext = () => useContext(MagicCreativeContext);

const agentFormGoals = [GoalSectionTitles.CONQUEST_LEADS, GoalSectionTitles.RETENTION];

interface MagicCreativeContextProviderProps {
  children: ReactNode;
}

export default function MagicCreativeContextProvider({
  children,
}: MagicCreativeContextProviderProps) {
  const [isValidForm, setIsValidForm] = useState(false);
  const [formState, setFormState] = useState<IListingGaol | IAgentGaol>(defaultAgentGoalValue);
  const [selectedGoal, setSelectedGoal] = useState<CampaignGoalType>(CampaignGoals[0]);

  const [activeStep, setActiveStep] = useState(steps[0]);

  const [generateHasError, setGenerateHasError] = useState(false);
  const [useGeneratedBannersLoading, setUseGeneratedBannersLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generatedBanners, setGeneratedBanners] = useState<TGeneratedBanner[]>([]);

  useEffect(() => {
    if (agentFormGoals.includes(selectedGoal.name)) setFormState(defaultAgentGoalValue);
    else setFormState(defaultListingGoalValue);
  }, [selectedGoal]);

  const clearAllStates = () => {
    setFormState(defaultAgentGoalValue);
    setGeneratedBanners([]);
    setGenerateLoading(false);
    setUseGeneratedBannersLoading(false);
    setGenerateHasError(false);
  };

  const onSelectGoal = (goal: CampaignGoalType) => setSelectedGoal(goal);

  const onStepChange = (step: StepType) => setActiveStep(step);

  return (
    <MagicCreativeContext.Provider
      value={{
        formState: [formState, setFormState],
        isValidForm: [isValidForm, setIsValidForm],
        generateLoading: [generateLoading, setGenerateLoading],
        useGeneratedBannersLoading: [useGeneratedBannersLoading, setUseGeneratedBannersLoading],
        generatedBanners: [generatedBanners, setGeneratedBanners],
        generateHasError: [generateHasError, setGenerateHasError],
        selectedGoal,
        onSelectGoal,
        activeStep,
        onStepChange,
        clearAllStates,
      }}
    >
      {children}
    </MagicCreativeContext.Provider>
  );
}
